import {
  Alert,
  ConfirmModal,
  Text,
  showToast,
  type AlertContent,
} from "@kablamooss/geo-ds-core-components";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { styled } from "styled-components";
import {
  getGetProjectsProjectIdRunsQueryKey,
  getGetProjectsProjectIdRunsRunIdQueryKey,
  useGetProjectsProjectIdRuns,
  usePostProjectsProjectIdRunsRunIdAccept,
} from "../../../.rest-hooks/runs";
import { getScenarioLabel } from "../../../config/projects";
import type { Run } from "../../../types";
import deserializeJsonApi from "../../../utils/deserializeJsonApi/deserializeJsonApi";

const StyledModalContent = styled.div`
  display: grid;
  gap: 0.5rem;
  padding-top: 0.5rem;
`;

interface AcceptScenarioRunModalProps {
  isOpen: boolean;
  onClose: () => void;
  run: Run;
}

const AcceptScenarioRunModal = ({
  isOpen,
  onClose,
  run,
}: AcceptScenarioRunModalProps) => {
  const [acceptRunError, setAcceptRunError] = useState<AlertContent | null>(
    null,
  );

  const projectId = run.project.id;
  const runId = run.id;
  const queryClient = useQueryClient();

  const { data } = useGetProjectsProjectIdRuns(
    projectId,
    {
      page: 1,
      perPage: 100,
    },
    {
      query: {
        enabled: !!projectId && isOpen,
        select: (response) => deserializeJsonApi(response.data),
      },
    },
  );

  const acceptedRun = data?.data.find(
    (siblingRuns) =>
      siblingRuns.type === "PhoenixPostprocessing" &&
      siblingRuns.accepted === true &&
      siblingRuns.scenario === run.scenario,
  );

  const { mutate: acceptScenarioRun } = usePostProjectsProjectIdRunsRunIdAccept(
    {
      mutation: {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: getGetProjectsProjectIdRunsRunIdQueryKey(
              projectId,
              runId,
            ),
          });
          void queryClient.invalidateQueries({
            queryKey: getGetProjectsProjectIdRunsQueryKey(projectId),
          });

          showToast({
            title: "Run accepted",
            variant: "success",
          });
        },
        onError: () => {
          showToast({
            title: "Unable to accept run",
            variant: "error",
          });
        },
      },
    },
  );

  const onConfirm = () => {
    try {
      return acceptScenarioRun({ projectId, runId });
    } catch (error) {
      setAcceptRunError({
        title: "Unable to accept run",
        message: "Please try again.",
      });
    }
  };

  const onConfirmSuccess = () => {
    onClose();
  };

  return (
    <ConfirmModal
      confirmLabel="Accept run"
      error={
        acceptRunError && (
          <Alert title={acceptRunError.title} variant="error">
            {acceptRunError.message}
          </Alert>
        )
      }
      isOpen={isOpen}
      onCancel={onClose}
      onConfirm={onConfirm}
      onConfirmSuccess={onConfirmSuccess}
      title={`Accept ${run.name} as the ${
        run.scenario ? getScenarioLabel(run.scenario) : "—"
      } scenario run`}
    >
      <StyledModalContent>
        {acceptedRun && (
          <Alert variant="warning" title="">
            Accepting {run.name} will override {acceptedRun.name} as the
            accepted run.
          </Alert>
        )}
        <Text size="subtitleSm">
          By accepting this run, you acknowledge it as the most accurate
          representation of the scenario and would like to clone it for the
          first run of the following scenario.
        </Text>
      </StyledModalContent>
    </ConfirmModal>
  );
};

export default AcceptScenarioRunModal;

import {
  Field,
  Select,
  TextInput,
  type FormControlOption,
} from "@kablamooss/geo-ds-core-components";
import { Controller, useFormContext } from "react-hook-form";
import { styled } from "styled-components";
import {
  AppRunType,
  ServiceProjectTypeAttribute,
} from "../../../.rest-hooks/types";
import {
  DESCRIPTION_MAX_LENGTH,
  DESCRIPTION_MAX_LENGTH_ERROR_MESSAGE,
} from "../../../config/constants";

const StyledAddFireBehaviourForm = styled.div`
  display: grid;
  gap: 1rem;
`;

const runTypeOptions: FormControlOption[] = [
  {
    label: "Fire Behaviour run",
    value: AppRunType.Phoenix,
  },
  {
    label: "Impact Analysis run",
    value: AppRunType.PhoenixPostprocessing,
  },
];

export interface CloneScenarioRunModalFormValues {
  description: string;
  projectId: string;
  runType: FormControlOption<AppRunType> | null;
}

interface GetDefaultValuesProps {
  projectType: ServiceProjectTypeAttribute;
}

export const getDefaultValues = ({
  projectType,
}: GetDefaultValuesProps): CloneScenarioRunModalFormValues => {
  return {
    description: "",
    projectId: "",
    runType:
      projectType === ServiceProjectTypeAttribute["modelled-impact-time"]
        ? {
            label: "Fire Behaviour run",
            value: AppRunType.Phoenix,
          }
        : null,
  };
};

interface CloneScenarioRunModalFormProps {
  projectType: ServiceProjectTypeAttribute;
}

const CloneScenarioRunModalForm = ({
  projectType,
}: CloneScenarioRunModalFormProps) => {
  const {
    control,
    formState: { errors, isSubmitting },
    register,
  } = useFormContext<CloneScenarioRunModalFormValues>();

  return (
    <StyledAddFireBehaviourForm>
      {projectType !== ServiceProjectTypeAttribute["modelled-impact-time"] && (
        <Controller
          control={control}
          name="runType"
          render={({ field, fieldState }) => (
            <Field
              error={fieldState.error}
              htmlFor="run-type-toggle-button"
              label="To create a new:"
            >
              <Select
                {...field}
                id="runType"
                disabled={isSubmitting}
                options={runTypeOptions}
                placeholder="Select"
                validationStatus={fieldState.error && "error"}
              />
            </Field>
          )}
          rules={{
            validate: (value) => !!value || "Please select a run",
          }}
        />
      )}
      <Field
        error={errors.description}
        htmlFor="description"
        label="Description"
        hint={`Describe why you are adding a new run within ${DESCRIPTION_MAX_LENGTH} characters`}
      >
        <TextInput
          {...register("description", {
            required: "Description is required",
            maxLength: {
              value: DESCRIPTION_MAX_LENGTH,
              message: DESCRIPTION_MAX_LENGTH_ERROR_MESSAGE,
            },
          })}
          disabled={isSubmitting}
          id="description"
          validationStatus={errors.description && "error"}
        />
      </Field>
    </StyledAddFireBehaviourForm>
  );
};

export default CloneScenarioRunModalForm;
